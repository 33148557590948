import axios from '@/axios-zaconte';

import {
  PhotoInterface,
  PhotosResponse,
  PhotosPage,
  PhotoUploadData,
  PhotoCategory,
} from '@/interfaces/Photo';
import CustomError from './models/CustomError';

export class Photo implements PhotoInterface {
  id = 0;
  guid = '';
  size = 0;
  uploaded = '';
  url = '';
  url_ = '';

  static create(
    id: number,
    guid: string,
    size: number,
    uploaded: string,
    url: string,
    url_: string
  ): Photo {
    const photo = new Photo();
    photo.id = id;
    photo.guid = guid;
    photo.size = size;
    photo.url = url;
    photo.url_ = url_;
    photo.uploaded = uploaded;
    return photo;
  }

  static async upload(data: PhotoUploadData) {
    try {
      for (const file of data.files) {
        const dataBody = new FormData();
        dataBody.append('file', file);
        dataBody.append('category', data.category.toString());
        await axios.post('/images', dataBody);
      }
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  static async fetchAll(
    page: number,
    pageSize: number,
    category: PhotoCategory,
    showAll = true
  ): Promise<PhotosPage> {
    try {
      const result = (await axios.get('/images', {
        params: { Page: page, PageSize: pageSize, category, ShowAll: showAll },
      })) as PhotosResponse;
      const photos: Photo[] = [];
      if (
        result.status === 200 &&
        result.data &&
        result.data.data &&
        result.data.data.length > 0
      ) {
        result.data.data.forEach(photo => {
          photos.push(
            Photo.create(
              photo.id,
              photo.guid,
              photo.size,
              photo.uploaded,
              photo.url,
              photo.url_
            )
          );
        });
      }
      return {
        photos,
        page: result.data.page,
        pages: result.data.pages,
        rows: result.data.rows,
      };
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async delete() {
    try {
      await axios.delete(`/images/${this.id}`);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  static async update(id: number, file: File) {
    try {
      const dataBody = new FormData();
      dataBody.append('file', file);
      await axios.patch(`/images/${id}`, dataBody)
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}

<template>
  <div :class="containerClass" @click="onClick($event)" :style="style">
    <div class="p-hidden-accessible">
      <input
        ref="input"
        type="checkbox"
        :checked="checked"
        :value="value"
        v-bind="$attrs"
        @focus="onFocus"
        @blur="onBlur"
      />
    </div>
    <div
      ref="box"
      :class="[
        'p-checkbox-box',
        {
          'p-highlight': checked,
          'p-disabled': $attrs.disabled,
          'p-focus': focused,
        },
      ]"
      role="checkbox"
      :aria-checked="checked"
    >
      <span :class="['p-checkbox-icon', { 'pi pi-check': checked }]"></span>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  emits: ['click', 'update:modelValue', 'change'],
  props: {
    value: null,
    modelValue: null,
    binary: Boolean,
    class: null,
    style: null,
    uid: null,
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    onClick(event) {
      if (!this.$attrs.disabled) {
        let newModelValue;

        if (this.binary) {
          newModelValue = !this.modelValue;
        } else {
          if (this.checked)
            newModelValue = this.modelValue.filter(
              val => val[this.uid] !== this.value[this.uid]
            );
          else
            newModelValue = this.modelValue
              ? [...this.modelValue, this.value]
              : [this.value];
        }
        this.$emit('click', event);
        this.$emit('update:modelValue', newModelValue);
        this.$emit('change', event);
        this.$refs.input.focus();
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    contains(value, pool) {
      let finded = false;
      if (value && pool && pool.length) {
        finded = pool.some(item => {
          return item[this.uid] === value[this.uid];
        });
      }
      return finded;
    },
  },
  computed: {
    checked() {
      return this.binary
        ? this.modelValue
        : this.contains(this.value, this.modelValue);
    },
    containerClass() {
      return [
        'p-checkbox p-component',
        this.class,
        {
          'p-checkbox-checked': this.checked,
          'p-checkbox-disabled': this.$attrs.disabled,
          'p-checkbox-focused': this.focused,
        },
      ];
    },
  },
};
</script>

import { Photo } from '@/classes/Photo';

export enum PhotoCategory {
  TEMP,
  DONOR,
  PART,
  USER,
}

export interface PhotoUploadData {
  files: File[];
  category: PhotoCategory;
}

export interface PhotoInterface {
  id: number;
  guid: string;
  size: number;
  uploaded: string;
  url: string;
  url_: string;
}

export interface PhotosResponse {
  config?: object;
  data: {
    data: PhotoInterface[];
    page: number;
    pages: number;
    rows: number;
  };
  status: number;
  response?: object;
  error?: {
    message: string;
    details: string;
  };
}

export interface PhotoResponse {
  config?: object;
  data?: PhotoInterface;
  status: number;
  response?: object;
  error?: {
    message: string;
    details: string;
  };
}

export interface PhotosPage {
  photos: Photo[];
  page: number;
  pages: number;
  rows: number;
}

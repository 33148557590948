import { createModule } from 'vuexok';
import store from '@/store';
import { Photo } from '@/classes/Photo';
import { PhotoCategory, PhotoUploadData } from '@/interfaces/Photo';
import CustomError from '@/classes/models/CustomError';

export const photoModule = createModule(store, 'photo', {
  state: {
    fetchedPhotos: new Array<Photo>(),
    pages: 1,
    rows: 1
  },
  getters: {
    photos(state): Photo[] {
      return state.fetchedPhotos;
    }
  },
  mutations: {
    setPhotos(state, payload: Photo[]) {
      state.fetchedPhotos = payload;
    },

    setFetched(
      state,
      payload: { photos: Photo[]; pages: number; rows: number }
    ) {
      state.fetchedPhotos = payload.photos;
      state.pages = payload.pages;
      state.rows = payload.rows;
    }
  },
  actions: {
    async fetchAllPhotos(
      state,
      payload: {
        page: number;
        pageSize: number;
        category: PhotoCategory;
        showAll: boolean;
      }
    ) {
      try {
        const result = await Photo.fetchAll(
          payload.page,
          payload.pageSize,
          payload.category,
          payload.showAll
        );
        photoModule.mutations.setFetched({
          photos: result.photos,
          pages: result.pages,
          rows: result.rows
        });
      } catch (error) {
        throw new CustomError(error.response);
      }
    },

    async uploadPhoto(state, data: PhotoUploadData) {
      await Photo.upload(data);
    },

    async updatePhoto(state, data: { id: number; file: File }) {
      try {
        await Photo.update(data.id, data.file);
      } catch (error) {
        throw new CustomError(error.response);
      }
    },

    async deletePhotos(state, photos: Photo[]) {
      for (const photo of photos) {
        await photo.delete();
      }
    }
  }
});


import { defineComponent, PropType, computed } from 'vue';

import Button from 'primevue/button';

import { Photo } from '@/classes/Photo';
import { dateFilter } from '@/utils/filters';

import MyCheckbox from '@/components/UI/Checkbox.vue';

export default defineComponent({
  emits: ['onDelete', 'update:modelValue', 'clicked'],
  props: {
    photo: {
      type: Object as PropType<Photo>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<Photo[]>,
    },
    value: {
      type: Object as PropType<Photo>,
      required: true,
    },
    mini: Boolean,
    id: Number,
  },
  components: {
    MyCheckbox,
    Button
  },

  setup(props, ctx) {
    const inputVal = computed({
      get: () => props.modelValue || [],
      set: val => {
        ctx.emit('update:modelValue', val);
      },
    });

    const checked = computed(() => {
      if (inputVal.value && props.value) {
        return inputVal.value.find(p => props.value.id === p.id);
      } else {
        return false;
      }
    });

    const previewUrl = computed(() => {
      return `${process.env.VUE_APP_IMAGE_URL}${props.photo.url_}`;
    });

    const uploadDate = computed(() => {
      return dateFilter(props.photo.uploaded, 'datetime');
    });

    const toggle = () => {
      if (inputVal.value) {
        if (inputVal.value.find(p => props.value.id === p.id)) {
          inputVal.value = [...inputVal.value.filter(p => p.id !== props.value.id)];
        } else {
          inputVal.value = [...inputVal.value, props.value];
        }
      } else {
        inputVal.value = [props.value];
      }
    };

    const photoClicked = () => {
      ctx.emit('clicked', props.photo);
    }

    const deleteHandler = () => {
      ctx.emit('onDelete', props.photo);
    };

    return {
      checked,
      previewUrl,
      uploadDate,
      toggle,
      deleteHandler,
      photoClicked
    };
  },
});


import { ref, defineComponent, onMounted, PropType, computed } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

import Toolbar from 'primevue/toolbar';
import SplitButton from 'primevue/splitbutton';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Dialog from 'primevue/dialog';
import Paginator from 'primevue/paginator';

import { Photo } from '@/classes/Photo';
import { PhotoCategory } from '@/interfaces/Photo';
import { photoModule } from '@/store/modules/photo';
import { localsModule } from '@/store/modules/locals';

import PhotoItem from './PhotoItem.vue';
import CustomError from '@/classes/models/CustomError';

export default defineComponent({
  emits: ['getSelected', 'update:modelValue'],
  components: {
    'photo-item': PhotoItem,
    Toolbar,
    SplitButton,
    Button,
    Dropdown,
    FileUpload,
    Dialog,
    Paginator,
    VueCropper,
  },
  props: {
    modelValue: {
      type: Object as PropType<Photo[]>,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canSelect: {
      type: Boolean,
      default: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const loading = ref(false);
    const selected = ref([]);
    const page = ref(1);
    const pageSize = ref(20);
    const category = ref<PhotoCategory>();
    const deleteProductDialog = ref(false);
    // eslint-disable-next-line
    const uploader = ref<any>(null);
    // eslint-disable-next-line
    const cropper = ref<any>(null);
    const showAll = ref(true);
    const previewImage = ref('');
    const fullPhotoDialog = ref(false);

    let previewId = 0;

    const confirm = useConfirm();
    const toast = useToast();
    const categories = [
      { title: 'Нераспределенные', value: PhotoCategory.TEMP },
      { title: 'Пользователи', value: PhotoCategory.USER },
      { title: 'Доноры', value: PhotoCategory.DONOR },
      { title: 'Запчасти', value: PhotoCategory.PART },
    ];

    const rows = computed(() => {
      return photoModule.state.rows;
    });

    const inputVal = computed({
      get: () => props.modelValue || [],
      set: val => {
        ctx.emit('update:modelValue', val);
      },
    });

    const photoClicked = async (photo: Photo) => {
      fullPhotoDialog.value = true;
      previewImage.value = `${process.env.VUE_APP_IMAGE_URL}${photo.url}`;
      previewId = photo.id;

      setTimeout(() => {
        const canvasData = cropper.value.getCanvasData();
        console.log(canvasData);
        // cropper.value.setCropBoxData(canvasData.left, canvasData.top, canvasData.width, canvasData.height);
      }, 1);
    };

    const rotateSelected = (deg: number) => {
      if (previewImage.value) {
        cropper.value.rotate(deg);
      }
    };

    const isDisable = computed(() => {
      return false;
    });

    const photos = computed(() => {
      return photoModule.getters.photos;
    });

    const update = () => {
      loading.value = true;
      try {
        if (category.value) {
          localsModule.mutations.setGalleriaSelectedCategory(category.value);
          photoModule.actions.fetchAllPhotos({
            page: page.value,
            pageSize: pageSize.value,
            category: category.value,
            showAll: showAll.value,
          });
        }
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    const croppNUpload = () => {
      cropper.value.getCroppedCanvas().toBlob(async (blob) => {
        try {
          await photoModule.actions.updatePhoto({ id: previewId, file: blob });
          fullPhotoDialog.value = false;
          update();
          toast.add({
            severity: 'success',
            summary: 'Успешно',
            detail: 'Изображение было успешно изменено',
            life: 5000
          })
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
      }, 'image/jpeg', 1);
    };

    const confirmCropp = () => {
      confirm.require({
        message: 'После применения оригинальное изображение будет утрачено. Вы уверены?',
        header: 'Подтверждение',
        icon: 'pi pi-exclamation-triangle',
        accept: croppNUpload
      })
    };

    onMounted(() => {
      category.value = localsModule.state.galleriaSelectedCategory;
      update();
    });

    // eslint-disable-next-line
    const updatePage = (event: any) => {
      page.value = event.page + 1;
      pageSize.value = event.rows;
      update();
    };

    const uploadHandler = async (event: { files: File[] }) => {
      if (event.files.length > 0) {
        loading.value = true;
        try {
          if (category.value) {
            await photoModule.actions.uploadPhoto({
              files: event.files,
              category: category.value,
            });
            update();
          }
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      }
      uploader.value.clear();
    };

    const confirmDeletePhoto = () => {
      deleteProductDialog.value = true;
    };

    const deleteHandler = async () => {
      if (inputVal.value && inputVal.value.length > 0) {
        loading.value = true;
        try {
          await photoModule.actions.deletePhotos(inputVal.value);
          update();
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      }
      deleteProductDialog.value = false;
    };

    const getSelectedHandler = () => {
      ctx.emit('getSelected', inputVal.value);
      ctx.emit('update:modelValue', inputVal.value);
    };

    const showAllSwitchHandler = () => {
      showAll.value = !showAll.value;
      update();
    };

    const buttons = [
      { label: 'Удалить', icon: 'pi pi-times', command: confirmDeletePhoto },
    ];

    return {
      loading,
      cropper,
      fullPhotoDialog,
      isDisable,
      photos,
      buttons,
      categories,
      category,
      uploader,
      inputVal,
      pageSize,
      rows,
      selected,
      deleteProductDialog,
      showAll,
      previewImage,
      confirmCropp,
      croppNUpload,
      rotateSelected,
      getSelectedHandler,
      update,
      uploadHandler,
      confirmDeletePhoto,
      updatePage,
      deleteHandler,
      showAllSwitchHandler,
      photoClicked,
    };
  },
});
